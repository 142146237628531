$z-index-header: 10000;
$z-index-logo: 9000;

// Content width
$content-width: 1040px;

$font-en: 'Roboto', sans-serif;
$font-yakuhan: YakuHanJP,YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','ヒラギノ角ゴシック','Hiragino Sans','メイリオ', Meiryo,'Meiryo UI','ＭＳ Ｐゴシック','MS PGothic',sans-serif;
$font-mix: YakuHanJP,YuGothic,'Yu Gothic','Roboto','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','ヒラギノ角ゴシック','Hiragino Sans','メイリオ', Meiryo,'Meiryo UI','ＭＳ Ｐゴシック','MS PGothic',sans-serif;
$font-ja: YakuHanJP,YuGothic,'Yu Gothic','Roboto','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','ヒラギノ角ゴシック','Hiragino Sans','メイリオ', Meiryo,'Meiryo UI','ＭＳ Ｐゴシック','MS PGothic',sans-serif;
