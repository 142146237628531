@use "../setting/setting" as m;
@use "variable" as var;

.top-loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: #fff;
  &_container{
    background: #fff;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_logo{
    img{
      opacity: 0;
      @include m.mq('middle') {
        width: 100px;
      }
    }
  }
}

.header{
  background: #fff;
  position: fixed;
  z-index: 1111;
  left: 0;
  top: 0;
  width: 100%;
  &_container{
    position: relative;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    @include m.mq('middle') {
      height: 70px;
    }
  }
  &_navi{
    display: flex;
    font-family: var.$font-en;
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    left: 120px;
    top: 28px;
    @include m.mq('middle') {
      display: none;
    }
    &_item{
      border: 1.5px solid #000;
      padding: 7px 8px 5px;
      margin-right: 10px;
      // &:after{
      //   content: "|";
      //   margin: 0 10px;
      // }
      &:last-child{
        margin-right: 0px;
        // &:after{
        //   display: none;
        // }
      }
    }
  }
  &_logo{
    margin: 0;
    width: 57px;
    @include m.mq('middle') {
      width: 57px;
    }
  }
  &_sns{
    display: flex;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 25px;
    @include m.mq('middle') {
      right: 20px;
      top: 29px;
    }
    &_item{
      margin-left: 30px;
      @include m.mq('middle') {
        margin-left: 10px;
        img{
          width: 20px;
        }
      }
    }
  }
  .drawer-hamburger{
    position: absolute;
    left: 30px;
    top: 0;
    width: 3.8rem;
    padding: 28px 20px 38px 20px;
    @include m.mq('middle') {
      width: 3.4rem;
      padding: 25px 20px 33px 20px;
      left: 0;
    }
    .drawer-hamburger-icon{
      height: 5px;
      @include m.mq('middle') {
        height: 3px;
      }
      &:before{
        height: 5px;
        top: -16px;
        @include m.mq('middle') {
          height: 3px;
          top: -12px;
        }
      }
      &:after{
        height: 5px;
        top: 16px;
        @include m.mq('middle') {
          height: 3px;
          top: 12px;
        }
      }
    }
  }
}
.drawer-overlay{
  ///z-index: 222;
}

.drawer-open{
  .header{
  }
  .drawer-hamburger{
    left: 25px !important;
    @include m.mq('middle') {
      left: 25px !important;
      top: 30px;
    }
    .drawer-hamburger-icon{
      &:before{
        top: 0 !important;
        background: #fff;
      }
      &:after{
        top: 0 !important;
        background: #fff;
      }
    }
  }
}
.drawer--left .drawer-nav{
  left: -520px;
  @include m.mq('middle') {
    left: -100vw;
  }
}
.drawer-nav{
  width: 520px;
  background: rgba(187,39,26,0.9);
  color: #fff;
  @include m.mq('middle') {
    width: 100vw;
  }
  .drawer-menu{
    display: flex;
    height: 100%;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    font-family: var.$font-en;
    font-size: 2.6rem;
    padding-left: 100px;
    letter-spacing: 0.05em;
    @include m.mq('middle') {
      padding-left: 50px;
      font-size: 2.4rem;
    }
  }
  .drawer-menu-item{
    padding: 3px 0;
    color: #fff;
    font-size: 2.6rem;
    @include m.mq('middle') {
      font-size: 2.4rem;
    }
    span{
      position: relative;
      &:after{
        content: "";
        display: inline-block;
        height: 2px;
        background: #fff;
        width: 0%;
        position: absolute;
        left: 0;
        bottom: -5px;
        transition: all 0.1s cubic-bezier(0.5, 0, 0.5, 1);
      }
    }
    &:hover{
      text-decoration: none;
      opacity: 1;
      span{
        &:after{
          width: 100%;
          transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
        }
      }
    }
  }
}
