@use "./setting/setting" as m;
@use "variable" as var;
.fixed-page-top{
  position: fixed;
  right: 64px;
  bottom: 170px;
  z-index: 1;
  width: 32.5px;
  height: 52px;
  @include m.mq('middle') {
    right: 20px;
    bottom: 75px;
    /*
    width: 16.25px;
    height: 26px;
    */
  }
  &:hover{
    opacity: 1;
  }
}
.fixed-ec{
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  width: 144px;
  height: 144px;
  opacity: 1;
  @include m.mq('middle') {
    right: 5px;
    bottom: 5px;
    width: 60px;
    height: 60px;
  }
  &:hover{
    opacity: 1;
  }
}

.footer-contents{
  position: relative;
  background: url(./assets/img/footer_contents_bg.jpg) no-repeat center center;
  background-size: cover;
  padding: 170px 0 200px;
  color: #fff;
  @include m.mq('middle') {
    padding: 85px 0 100px;
  }
  &:before,&:after{
    position: absolute;
    content: "";
    display: inline-block;
    width: 14px;
    height: 270px;
    @include m.mq('middle') {
      width: 10px;
      height: 192.86px;
    }
  }
  &:before{
    background: url(./assets/img/footer_contents_txt-01.png) no-repeat center center;
    background-size: cover;
    left: 4.16vw;
    top: 0;
    bottom: 0;
    margin: auto;
    @include m.mq('middle') {
      left: 5px;
    }
  }
  &:after{
    background: url(./assets/img/footer_contents_txt-02.png) no-repeat center center;
    background-size: cover;
    right: 4.16vw;
    top: 0;
    bottom: 0;
    margin: auto;
    @include m.mq('middle') {
      right: 5px;
    }
  }
  &_container{
    max-width: 900px;
    margin: 0 auto;
    @include m.mq('middle') {
      padding: 0 20px;
    }
  }
  &_inner{
    width: 475px;
    text-align: center;
    @include m.mq('middle') {
      width: auto;
    }
  }
  &_heading{
    margin-bottom: 55px;
    @include m.mq('middle') {
      margin-bottom: 20px;
      img{
        width: 290px;
      }
    }
  }
  &_txt-ja{
    font-weight: bold;
    margin-bottom: 30px;
    @include m.mq('middle') {
      font-size: 1.1rem;
      margin-bottom: 15px;
    }
  }
  &_txt-en{
    font-size: 1rem;
    font-family: var.$font-en;
    letter-spacing: 0.05em;
    margin-bottom: 0;
    @include m.mq('middle') {
      font-size: 0.9rem;
    }
  }
}

.footer{
  padding-bottom: 30px;
  //overflow-x: hidden;
  &_page-top{
    position: relative;
    z-index: 0;
    text-align: center;
    margin-top: -125px;
    margin-bottom: -85px;
    @include m.mq('middle') {
      margin-top: -55px;
      margin-bottom: -25px;
      img{
        width: 120px;
      }
    }
    a{
      &:hover{
        opacity: 1;
      }
    }
  }
  &_container{
    max-width: var.$content-width;
    margin: 0 auto;
    @include m.mq('middle') {
      padding: 0 20px;
    }
  }
  &_info{
    position: relative;
    z-index: 1;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 30px;
    @include m.mq('middle') {
      font-size: 1.2rem;
      margin-bottom: 15px;
    }
  }
  &_logo-list{
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 40px;
    @include m.mq('middle') {
      margin-bottom: 20px;
    }
    &_item{
      margin: 0 20px;
      @include m.mq('middle') {
        margin: 0 10px;
      }
      &:first-child{
      }
      &--bj{
        position: relative;
        top: 5px;
      }
    }
  }
  &_navi{
    font-family: var.$font-en;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    letter-spacing: 0.1em;
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    @include m.mq('middle') {
      font-size: 1.1rem;
    }
    &_item{
      &:after{
        content: "|";
        margin: 0 10px;
        @include m.mq('middle') {
          margin: 0 5px;
        }
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
    }
  }
  &_copy{
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    @include m.mq('middle') {
      font-size: 0.9rem;
    }
  }
}
