@use "./setting/setting" as m;
@use "variable" as var;

// html{

// }
/* -------------------- WordPress -------------------- */
.aligncenter{
  display: block;
  margin: 0 auto;
}
.alignright{
  float: right;
}
.alignleft{
  float: left;
}
.ff-en{
  font-family: var.$font-en;
}
.ff-ja{
  font-family: var.$font-ja;
  letter-spacing: -0.05em;
}

body{
  //overflow-x: hidden;
  padding-top: 80px;
  @include m.mq('middle') {
    padding-top: 70px;
  }
}
main{
  overflow-x: hidden;
  background: #F8F8F8;
}

.pc-none{
  @include m.mq('large') {
    display: none;
  }
}
.sp-none{
  @include m.mq('middle') {
    display: none;
  }
}


.scrollable-table {
  overflow-x: auto; /* 横方向にスクロールを許可 */
  white-space: nowrap; /* コンテンツを折り返さない */
}

.scrollable-table table {
  min-width: 100%; /* テーブルの最小幅を100%に設定 */
  table-layout: auto !important;
  /* 必要に応じてテーブルの幅を調整 */
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon-new{
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #BB271A;
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  @include m.mq('middle') {
    width: 30px;
    height: 20px;
    font-size: 0.9rem;
  }
}
.icon-blank{
  width: 42px;
  height: 42px;
  background: url(./assets/img/icon_blank.png) no-repeat center center;
  background-size: cover;
  position: absolute;
  right: 8px;
  bottom: 6px;
  z-index: 1;
  @include m.mq('middle') {
    width: 30px;
    height: 30px;
    right: 5px;
    bottom: 5px;
  }
}

.icon-youtube{
  width: 49px;
  height: 34.5px;
  background: url(./assets/img/icon_youtube.png) no-repeat center center;
  background-size: cover;
  position: absolute;
  right: 10px;
  bottom: 9px;
  z-index: 1;
  @include m.mq('middle') {
    width: 34px;
    height: 23.94px;
    right: 5px;
    bottom: 5px;
  }
}
.read-more{
  position: absolute;
  //right: 100px;
  right: 0;
  top: -30px;
  width: 155px;
  height: 155px;
  z-index: 1;
  @include m.mq('middle') {
    width: 60px;
    height: 60px;
    right: 20px;
    top: 10px;
  }
  a{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      opacity: 1;
      .read-more_arrow{
        transform: translateX(10px);
        transition: transform 0.3s ease-out;
      }
    }
  }
  &_bg{
    position: absolute;
    left: 0;
    top: 0;
    animation: rotateAnimation 30s linear infinite;
  }
  &_arrow{
    position: relative;
    top: 5px;
    transition: transform 0.2s ease-out;
    @include m.mq('middle') {
      top: 7px;
    }
    img{
      @include m.mq('middle') {
        width: 30px;
      }
    }
  }
}

.more-ajax-btn{
  position: relative;
  max-width: var.$content-width;
  margin: 0 auto;
  background: #333333;
  color: #fff;
  font-size: 2.2rem;
  font-weight: bold;
  font-family: var.$font-en;
  text-align: center;
  @include m.mq('middle') {
    font-size: 1.8rem;
  }
  &:after{
    content: "";
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(./assets/img/icon_plus.png) no-repeat center center;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 50px;
    bottom: 0;
    margin: auto;
    @include m.mq('middle') {
      width: 18px;
      height: 18px;
      right: 30px;
    }
  }
  a{
    display: block;
    color: #fff;
    padding: 15px;
  }
}
.heading-ptn-01{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 5px 50px;
  background: url(./assets/img/heading_ptn_01_bg.png) no-repeat left center;
  background-size: contain;
  height: 130px;
  margin-bottom: 60px;
  @include m.mq('middle') {
    padding: 0 70px 5px 25px;
    height: 80px;
    margin-bottom: 35px;
  }
  &_line{
    width: 0%; /* 初期幅を100%に設定 */
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #333;
    overflow: hidden;
  }
  &_en{
    display: block;
    font-family: var.$font-en;
    font-size: 5.8rem;
    opacity: 0;
    @include m.mq('middle') {
      font-size: 3rem;
      line-height: 1.2;
    }
  }
  &_ja{
    display: block;
    font-size: 2.2rem;
    opacity: 0;
    @include m.mq('middle') {
      font-size: 1.1rem;
    }
    &:before{
      content: "";
      display: inline-block;
      width: 40px;
      height: 1px;
      background: #333333;
      vertical-align: middle;
      margin-right: 10px;
      @include m.mq('middle') {
        width: 15px;
        margin-right: 5px;
      }
    }
  }
  &--sp-small{
    .heading-ptn-01_en{
      @include m.mq('middle') {
        font-size: 2.2rem;
      }
    }
    .heading-ptn-01_ja{
      @include m.mq('middle') {
        font-size: 0.9rem;
      }
    }
  }
}

.heading-ptn-02{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 5px 50px;
  background: url(./assets/img/heading_ptn_01_bg.png) no-repeat left center;
  background-size: contain;
  height: 130px;
  margin-bottom: 60px;
  @include m.mq('middle') {
    padding: 0 0 5px 25px;
    height: 80px;
    margin-bottom: 35px;
  }
  &_line{
    width: 0%; /* 初期幅を100%に設定 */
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #333;
    overflow: hidden;
  }
  &_en{
    display: block;
    font-family: var.$font-en;
    font-size: 5.8rem;
    @include m.mq('middle') {
      font-size: 2.9rem;
    }
  }
  &_ja{
    display: block;
    font-size: 2.2rem;
    @include m.mq('middle') {
      font-size: 1.1rem;
    }
    &:before{
      content: "";
      display: inline-block;
      width: 40px;
      height: 1px;
      background: #333333;
      vertical-align: middle;
      margin-right: 10px;
      @include m.mq('middle') {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}

.heading-ptn-03{
  font-family: var.$font-en;
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 45px;
  @include m.mq('middle') {
    font-size: 3.2rem;
    margin-bottom: 25px;
  }
}

.zoom-element{
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  will-change: transform;
}

#breadcrumb{
  background: #F8F8F8;
  padding: 20px 50px 40px;
  @include m.mq('middle') {
    padding: 10px 15px 20px;
  }
  .bread-container{
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 500;
    @include m.mq('middle') {
      font-size: 0.9rem;
    }
    a{
      &:after{
        content: "-";
        margin: 0 10px;
        @include m.mq('middle') {
          margin: 0 5px;
        }
      }
    }
  }
}

.lower{
  &-container{
    max-width: var.$content-width;
    margin: 0 auto;
    @include m.mq('middle') {
      padding: 0 20px;
    }
  }
}
