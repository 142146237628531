@use "../setting/setting" as m;
@use "variable" as var;

.magazine-archive{
  display: flex;
  flex-wrap: wrap;
  &--col-03{
    .magazine-archive_item{
      position: relative;
      width: 30%;
      margin-right: 5%;
      margin-bottom: 50px;
      @include m.mq('large') {
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include m.mq('middle') {
        width: 48%;
        margin-right: 4%;
        margin-bottom: 20px;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
  &--col-04{
    .magazine-archive_item{
      position: relative;
      width: 23.5%;
      margin-right: 2%;
      margin-bottom: 50px;
      @include m.mq('large') {
        &:nth-child(4n){
          margin-right: 0;
        }
      }
      @include m.mq('middle') {
        width: 48%;
        margin-right: 4%;
        margin-bottom: 20px;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
  &_item{
    &_thumb{
      position: relative;
      margin-bottom: 15px;
      text-align: center;
      @include m.mq('middle') {
        margin-bottom: 10px;
      }
      a{
        display: block;
      }
    }
    &_time{
      font-family: var.$font-en;
      display: block;
      font-weight: 400;
      @include m.mq('middle') {
        font-size: 1.2rem;
      }
    }
    &_heading{
      font-size: 1.8rem;
      line-height: 1.55;
      margin: 0 0 10px 0;
      padding-bottom: 10px;
      font-weight: bold;
      border-bottom: 1px solid #C4C4C4;
      word-break: break-all;
      @include m.mq('middle') {
        margin: 5px 0 5px 0;
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }
    &_position{
      font-size: 1.4rem;
      margin-bottom: 0;
      @include m.mq('middle') {
        font-size: 1.2rem;
      }
    }
    &_name{
      font-size: 1.8rem;
      line-height: 1.5;
      font-weight: bold;
      margin-bottom: 15px;
      @include m.mq('middle') {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }
    &_info{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &_time{
        font-size: 1.4rem;
        color: #868686;
        margin: 0 5px 10px 0;
        line-height: 1;
      }
      .post-cat-list{}
    }
  }
}

.news-schedule-archive{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  &_item{
    position: relative;
    width: 30%;
    margin-right: 5%;
    margin-bottom: 100px;
    @include m.mq('large') {
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include m.mq('middle') {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 30px;
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    &_thumb{
      margin-bottom: 25px;
      text-align: center;
      @include m.mq('middle') {
        margin-bottom: 10px;
      }
      a{
        display: block;
      }
    }
    &_time{
      font-family: var.$font-en;
      display: block;
      font-weight: 400;
      @include m.mq('middle') {
        font-size: 1.2rem;
      }
    }
    &_cats{
      display: flex;
      flex-wrap: wrap;
      font-family: var.$font-en;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.7;
      @include m.mq('middle') {
        font-size: 0.9rem;
      }
      &_item{
        width: 110px;
        text-align: center;
        background: #fff;
        border: 0.5px solid #707070;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        @include m.mq('middle') {
          width: auto;
          margin-right: 4px;
          margin-bottom: 4px;
        }
        a{
          display: block;
          @include m.mq('middle') {
            padding: 1px 6px 0 6px;
          }
          &:before{
            content: "#";
            margin-right: 2px;
            @include m.mq('middle') {
              margin-right: 1px;
            }
          }
        }
      }
    }
    &_heading{
      font-size: 1.8rem;
      line-height: 1.55;
      margin: 15px 0 0 0;
      font-weight: bold;
      @include m.mq('middle') {
        margin: 5px 0 0 0;
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }
  }
}

.content-archive{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  &_item{
    position: relative;
    width: 23.5%;
    margin-right: 2%;
    margin-bottom: 50px;
    @include m.mq('large') {
      &:nth-child(4n){
        margin-right: 0;
      }
    }
    @include m.mq('middle') {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 20px;
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    &_thumb{
      position: relative;
      margin-bottom: 0;
      text-align: center;
      a{
        display: block;
      }
    }
    &_heading{
      font-size: 1.8rem;
      line-height: 1.55;
      margin: 25px 0 0 0;
      font-weight: bold;
      @include m.mq('middle') {
        font-size: 1.4rem;
        line-height: 1.5;
        margin: 10px 0 0 0;
      }
    }
  }
}

.sp-archive{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  &_item{
    width: 30%;
    margin-right: 5%;
    margin-bottom: 40px;
    @include m.mq('large') {
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include m.mq('middle') {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 20px;
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    a{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
    }
    &_thumb{
      position: relative;
      margin-bottom: 0;
      text-align: center;
      a{
        display: block;
      }
    }
    &_heading{
      font-size: 1.8rem;
      line-height: 1.55;
      margin: 25px 0 0 0;
      font-weight: bold;
      @include m.mq('middle') {
        font-size: 1.4rem;
        line-height: 1.5;
        margin: 10px 0 0 0;
      }
    }
    &_time{
      margin-top: auto;
      display: block;
      margin-top: 5px;
      @include m.mq('middle') {
        font-size: 1.1rem;
      }
    }
  }
}

.post-time{
  font-family: var.$font-en;
  font-size: 1.2rem;
  color: #787878;
  margin-bottom: 10px;
  display: block;
  @include m.mq('middle') {
    margin-bottom: 5px;
  }
}

.post-cat-list{
  display: flex;
  flex-wrap: wrap;
  font-family: var.$font-en;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.7;
  @include m.mq('middle') {
    font-size: 0.9rem;
  }
  &_item{
    width: 110px;
    text-align: center;
    background: #fff;
    border: 0.5px solid #707070;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    @include m.mq('middle') {
      width: auto;
      margin-right: 4px;
      margin-bottom: 4px;
    }
    &.active{
      background: #C4C4C4;
      a{
        pointer-events: none;
      }
    }
    a{
      display: block;
      @include m.mq('middle') {
        padding: 1px 6px 0 6px;
      }
      &:before{
        content: "#";
        margin-right: 2px;
        @include m.mq('middle') {
          margin-right: 1px;
        }
      }
    }
  }
}
