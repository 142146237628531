@use "./setting/setting" as m;
@use "variable" as var;

@keyframes scrollAnimation {
  0% {
    top: -20px;
  }
  100% {
    top: 80px; /* 白い線の高さと同じに設定 */
  }
}

.top{

  &_mainvisual{
    position: relative;
    .slider{
      display: none;
    }
    .slider.slick-initialized{
      display: block; /*slick-initializedが付与されたら表示*/
    }
    &_slider{
      line-height: 1;
      margin: 0 !important;
      div{
        line-height: 1;
      }
    }
    .slick-num{
      font-family: var.$font-en;
      position: absolute;
      left: 55px;
      bottom: 30px;
      font-size: 1.4rem;
      @include m.mq('middle') {
        font-size: 1.2rem;
        left: 30px;
        bottom: 15px;
      }
      .now-count{
        font-weight: bold;
        margin: 0 5px;
        @include m.mq('middle') {
          margin: 0 3px;
        }
      }
      .all-count{
        font-weight: bold;
        margin: 0 5px;
        @include m.mq('middle') {
          margin: 0 3px;
        }
      }
    }
    &_arrows{
      position: absolute;
      left: 22px;
      bottom: 27px;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      width: 115px;
      @include m.mq('middle') {
        width: 66px;
        left: 15px;
        bottom: 3px;
      }
      &_item{
        width: 11.5px;
        height: 21.5px;
        cursor: pointer;
        @include m.mq('middle') {
          width: 8px;
          height: auto;
        }
        &.top_mainvisual_slider_prev{
        }
        &.top_mainvisual_slider_next{
        }
      }
    }
  }

  &_magazine{
    background: #F8F8F8;
    padding: 100px 0;
    @include m.mq('middle') {
      padding: 60px 0 60px 0;
    }
    &_container{
      position: relative;
      max-width: 83.33vw;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
        padding: 0 20px;
      }
    }
    &_col-02{
      display: flex;
      justify-content: space-between;
      margin-bottom: 45px;
    }
    &_new{
      width: 49%;
      padding-left: 50px;
      &_head{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      }
      &_thumb{
        width: 125px;
        margin-bottom: 0;
      }
      &_info{
        padding-left: 20px;
        flex: 1;
        font-family: var.$font-en;
        font-size: 4rem;
        font-weight: bold;
        line-height: 1.3;
        &_num{
          &:before{
            content: "#";
          }
        }
        &_time{}
      }
      &_author{
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &_position{
          font-size: 1.4rem;
          margin-right: 10px;
        }
        &_name{
          font-size: 2rem;
        }
      }
      &_heading{
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 20px;
      }
    }
    &_figure{
      width: 40%;
      margin-bottom: 0;
    }
    &_past{
      position: relative;
      padding-left: 8.33vw;
      &_heading{
        font-family: var.$font-en;
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 25px;
        &:before{
          content: "";
          display: inline-block;
          width: 40px;
          height: 1px;
          background: #333;
          vertical-align: middle;
          position: relative;
          bottom: 1px;
          margin-right: 17px;
        }
      }
      .slick-list{
        overflow: visible;
      }
      &_slider-container{
        position: relative;
        //padding-left: 8.33vw;
        @include m.mq('middle') {
          padding-left: 20px;
        }
      }
      &_slider-inner{
        position: relative;
        overflow: hidden;
        padding-right: 16.66vw;
        @include m.mq('middle') {
          padding-right: 40px;
        }
      }
      .hellowtta-custom-arrow{
        &_item{
          display: inline-block;
          z-index: 1;
          width: 41px;
          height: 41px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          @include m.mq('middle') {
            width: 30px;
            height: 30px;
          }
          &.slick-disabled{
            opacity: 0.3;
            transition: all 0.3s ease-in-out;
            pointer-events: none;
          }
          &.custom-prev{
            background: url(./assets/img/icon_sliderarrow_left.png) no-repeat center center;
            background-size: cover;
            position: absolute;
            left: calc(4vw - 20px);
            top: 0;
            bottom: 0;
            margin: auto;
            @include m.mq('middle') {
              left: 5px;
            }
          }
          &.custom-next{
            background: url(./assets/img/icon_sliderarrow_right.png) no-repeat center center;
            background-size: cover;
            position: absolute;
            right: 2.08vw;
            top: 0;
            bottom: 0;
            margin: auto;
            @include m.mq('middle') {
              right: 5px;
            }
          }
        }
      }
    }
    &_past-archive{
      &_item{
        a{
          display: flex;
        }
        &_thumb{
          width: 70px;
          margin-right: 15px;
          a{
            display: block;
          }
        }
        &_contents{
          width: 305px;
        }
        &_info{
          font-size: 1.8rem;
          line-height: 1.5;
          font-family: var.$font-en;
          font-weight: bold;
          margin-bottom: 5px;
          &_num{
            margin-right: 15px;
            margin-bottom: 0;
            &:before{
              content: "#";
            }
          }
          &_time{
            margin-bottom: 0;
          }
        }
        &_author{
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 5px;
          &_position{}
          &_name{}
        }
        &_heading{
          font-size: 1.4rem;
          line-height: 1.6;
        }
      }
    }
  }

  &_tta-instagram{
    padding-bottom: 100px;
    @include m.mq('middle') {
      padding-bottom: 60px;
    }
    &_container{
      position: relative;
      max-width: 83.33vw;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
        padding: 0 20px;
      }
    }
    &_feed-container{
      position: relative;
      max-width: 85vw;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
        padding: 0 10px;
      }
    }
    &_head{
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      @include m.mq('middle') {
        margin-bottom: 25px;
      }
      &_heading{
        font-size: 2.4rem;
        font-weight: bold;
        border-left: 18px solid #333333;
        margin-bottom: 0;
        padding: 6px 0 6px 25px;
        margin-right: 50px;
        @include m.mq('middle') {
          font-size: 2rem;
          border-left: 13px solid #333333;
          padding: 6px 0 6px 20px;
        }
      }
      &_link{
        position: relative;
        top: 3px;
      }
    }
    #sb_instagram.sbi_mob_col_4 #sbi_images .sbi_item{
      @include m.mq('middle') {
        padding: 5px !important;
      }
    }
  }

  &_future{
    position: relative;
    padding: 90px 0 130px 0;
    background: #fff;
    @include m.mq('middle') {
      padding: 60px 0 60px 0;
    }
    .icon-new{
      left: 50px;
      @include m.mq('middle') {
        left: 20px;
      }
    }
    &_container{
      position: relative;
      max-width: 83.33vw;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
        padding: 0 20px;
      }
    }
    &_slider-container{
      position: relative;
      padding-left: 8.33vw;
      @include m.mq('middle') {
        padding-left: 20px;
      }
    }
    &_slider-inner{
      position: relative;
      overflow: hidden;
      padding-right: 16.66vw;
      @include m.mq('middle') {
        padding-right: 40px;
      }
    }
    .custom-arrow{
      &_item{
        display: inline-block;
        z-index: 1;
        width: 41px;
        height: 41px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        @include m.mq('middle') {
          width: 30px;
          height: 30px;
        }
        &.slick-disabled{
          opacity: 0.3;
          transition: all 0.3s ease-in-out;
          pointer-events: none;
        }
        &.custom-prev{
          background: url(./assets/img/icon_sliderarrow_left.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          left: calc(4vw - 20px);
          top: 0;
          bottom: 0;
          margin: auto;
          @include m.mq('middle') {
            left: 5px;
          }
        }
        &.custom-next{
          background: url(./assets/img/icon_sliderarrow_right.png) no-repeat center center;
          background-size: cover;
          position: absolute;
          right: 2.08vw;
          top: 0;
          bottom: 0;
          margin: auto;
          @include m.mq('middle') {
            right: 5px;
          }
        }
      }
    }
    &_slider-nav{
      padding: 0 0 0 0;
      display: flex;
      justify-content: center;
      div{
        width: auto !important;
      }
      &_item{
        cursor: pointer;
        width: 64px !important;
        margin: 0 15px;
        @include m.mq('middle') {
          width: 42px !important;
          margin: 0 3px;
        }
      }
      .slick-slide{
        position: relative;
        &:after{
          content: "";
          width: 0;
          transition: all 0.2s ease-in-out;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -15px;
          margin: auto;
          @include m.mq('middle') {
            bottom: -5px;
          }
        }
      }
      .slick-current{
        &:after{
          content: "";
          display: block;
          width: 40px;
          height: 5px;
          background: #BB271A;
          transition: width 0.2s ease-in-out;
          @include m.mq('middle') {
            width: 24px;
            height: 3px;
          }
        }
      }
    }
    .slick-list{
      overflow: visible;
      &_item{
        width: 64px !important;
      }
    }
    &_slider{
      overflow: visible !important;
      margin-bottom: 50px;
      @include m.mq('middle') {
        margin-bottom: 15px;
      }
      &_item{
        &_flex{
          display: flex;
          @include m.mq('middle') {
          }
        }
        &_thumb{
          width: 340px;
          margin: 0;
          @include m.mq('middle') {
            width: 40%;
          }
        }
        &_contents{
          position: relative;
          flex: 1;
          padding: 60px 50px 0 50px;
          @include m.mq('middle') {
            flex: auto;
            width: 60%;
            padding: 25px 20px 0 20px;
          }
        }
        &_heading{
          font-size: 2.6rem;
          line-height: 1.53;
          margin-bottom: 15px;
          @include m.mq('middle') {
            line-height: 1.4;
            font-size: 1.4rem;
            margin-bottom: 5px;
          }
        }
        &_txt{
          margin-bottom: 25px;
          @include m.mq('middle') {
            font-size: 0.9rem;
            margin-bottom: 5px;
          }
        }
        &_time{
          font-family: var.$font-en;
          font-size: 1rem;
          color: #787878;
          margin-bottom: 10px;
          display: block;
          @include m.mq('middle') {
            margin-bottom: 5px;
          }
        }
        &_cats{
          display: flex;
          flex-wrap: wrap;
          font-family: var.$font-en;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1.7;
          @include m.mq('middle') {
            font-size: 0.9rem;
          }
          &_item{
            width: 110px;
            text-align: center;
            background: #fff;
            border: 0.5px solid #707070;
            border-radius: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            @include m.mq('middle') {
              width: auto;
              margin-right: 4px;
              margin-bottom: 4px;
            }
            a{
              display: block;
              @include m.mq('middle') {
                padding: 1px 6px 0 6px;
              }
              &:before{
                content: "#";
                margin-right: 2px;
                @include m.mq('middle') {
                  margin-right: 1px;
                }
              }
            }
          }
        }
      }
    }
  }

  @keyframes rotate3D {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  &_txt-slide{
    position: relative;
    background: #fff;
    width: 100%;
    height: 352px;
    @include m.mq('middle') {
      height: 162px;
    }
    &_logo{
      z-index: 1;
      width: 250px;
      height: 250px;
      background: #fff;
      position: absolute;
      padding-bottom: 15px;
      left: 0;
      top: 0;
      right: 0;
      bottom: 15px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      //animation: rotate3D 5s linear infinite;
      @include m.mq('middle') {
        width: 110px;
        height: 110px;
        bottom: 0;
      }
      img{
        animation: rotate3D 5s linear infinite;
        @include m.mq('middle') {
          width: 65%;
          position: relative;
          top: 3px;
        }
      }
    }
    &_list{
      position: absolute !important;
      width: 100%;
      height: 176px;
      line-height: 1;
      //z-index: -1;
      margin: 0;
      overflow: hidden;
      @include m.mq('middle') {
        height: 88px;
        line-height: 1;
      }
      .slick-track{
      }
      .slick-slide{
        //margin: 0 125px;
        @include m.mq('middle') {
          //margin: 0 60px;
        }
      }
      &--01{
        left: 0;
        top: 0;
        @include m.mq('middle') {
          //bottom: 52px;
        }
      }
      &--02{
        left: 0;
        top: 155px;
        @include m.mq('middle') {
          top: 77px;
        }
      }
      &_item{
        //margin-right: 150px;
        img{
          max-width: none;
          width: 7220px; // 画像の幅
          //height: 104px;
          @include m.mq('middle') {
            width: 3610px; // 画像の幅
          }
        }
      }
    }
  }

  &_news-schedule{
    background: #fff;
    padding-top: 120px;
    @include m.mq('middle') {
      padding-top: 90px;
    }
    &_container{
      position: relative;
      max-width: 83.33vw;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
        padding: 0 20px;
      }
    }
  }

  &_content{
    background: #C4C4C3;
    padding: 95px 0;
    @include m.mq('middle') {
      padding: 60px 0;
    }
    &_container{
      position: relative;
      max-width: 83.33vw;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
        padding: 0 20px;
      }
    }
  }

  &_sp{
    padding: 100px 0;
    background: #C4C4C3;
    @include m.mq('middle') {
      padding: 60px 0;
    }
    &_container{
      position: relative;
      max-width: 83.33vw;
      margin: 0 auto;
      @include m.mq('middle') {
        max-width: 100%;
        padding: 0 20px;
      }
    }
  }

}
