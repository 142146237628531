@use "../setting/setting" as m;
@use "variable" as var;

.archive{

  &-category{
    margin: 100px 0 60px;
    @include m.mq('middle') {
      margin: 0 0 15px;
    }
  }

}

.post-archive{
  display: flex;
  flex-wrap: wrap;
  &_item{
    &.future{
      width: 295px;
      margin-right: 77px;
      margin-bottom: 90px;
      @include m.mq('large') {
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include m.mq('middle') {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 30px;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
    &.newsschedule,&.content,&.sparchives{
      width: 290px;
      margin-right: 85px;
      margin-bottom: 90px;
      @include m.mq('large') {
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include m.mq('middle') {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 30px;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
    &_thumb{
      a{
        display: block;
      }
    }
    &_title{
      font-size: 2rem;
      line-height: 1.5;
      margin-bottom: 10px;
      @include m.mq('middle') {
        line-height: 1.4;
        font-size: 1.4rem;
        margin-bottom: 5px;
      }
    }
    &_excerpt{
      font-size: 1.4rem;
      line-height: 1.42;
      margin-bottom: 15px;
      color: #868686;
      font-weight: bold;
      @include m.mq('middle') {
        font-size: 0.9rem;
        margin-bottom: 5px;
      }
    }
  }
}


.pagination{
  margin-bottom: 120px;
  font-family: var.$font-en;
  @include m.mq('middle') {
    margin-bottom: 60px;
  }
  &_container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-numbers{
    display: inline-block;
    font-size: 1.8rem;
    color: #868686;
    font-weight: bold;
    padding: 0 15px 5px;
    @include m.mq('middle') {
      font-size: 1.4rem;
      padding: 0 5px 5px;
    }
  }
  span{
    border-bottom: 1px solid #BB271A;
  }
  .prev{
    margin-right: auto;
    text-align: center;
    margin-left: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  .next{
    margin-left: auto;
    text-align: center;
    margin-right: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
