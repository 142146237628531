@use "../setting/setting" as m;
@use "variable" as var;

.about{

  &_head{
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 0 30px;
    @include m.mq('middle') {
      padding: 15px 20px 20px;
    }
    &_heading{
      font-family: var.$font-en;
      font-weight: bold;
      font-size: 5rem;
      margin-bottom: 0;
      @include m.mq('middle') {
        font-size: 3.2rem;
      }
    }
  }

  &_heading_en{
    font-size: 5rem;
    margin-left: 32px;
    @include m.mq('middle') {
      font-size: 2.2rem;
      margin-left: 15px;
    }
  }

  &_title_middle{
    display: flex;
    align-items: center;
  }

  &_container{
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 0;
    @include m.mq('middle') {
      padding: 0 20px 45px;
    }
  }

  &_sec{
    margin-bottom: 100px;
    @include m.mq('middle') {
      margin-bottom: 60px;
    }
  }

  &_concept{
    &_desc{
      &_txt{
        font-weight: bold;
        margin-bottom: 25px;
        @include m.mq('middle') {
          margin-bottom: 15px;
        }
      }
    }
    &_en{
      &_txt{
        font-family: var.$font-en;
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 20px;
        line-height: 1.66;
        @include m.mq('middle') {
          margin-bottom: 10px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  &_logo{
    &_thumb{
      text-align: center;
      margin-bottom: 25px;
      @include m.mq('middle') {
        img{
          width: 270px;
        }
      }
    }
    &_history{
      margin-top: -15px;
      margin-bottom: 60px;
      font-weight: bold;
      @include m.mq('middle') {
        margin-bottom: 20px;
      }
    }
    &_profile{
      &_name{
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 30px;
        @include m.mq('middle') {
          font-size: 1.8rem;
          margin-bottom: 15px;
        }
        span{
          display: inline-block;
        }
      }
      &_txt-ja{
        font-weight: bold;
        margin-bottom: 15px;
      }
      &_txt-en{
        font-family: var.$font-en;
        font-weight: 400;
        line-height: 1.7;
      }
    }
  }

  &_prologue{
    margin-bottom: 0;
    &_content{
      background: #fff;
      box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
      text-align: center;
      padding: 100px 15px;
      @include m.mq('middle') {
        padding: 45px 30px;
      }
    }
  }

  &_access{
    padding-top: 100px;
    @include m.mq('middle') {
      padding-top: 60px;
    }
    &_txt-01{
      font-weight: bold;
      margin-bottom: 5px;
    }
    &_txt-02{
      font-size: 1.2rem;
      font-family: var.$font-en;
      font-weight: 400;
      margin-bottom: 35px;
    }
    &_map{
      iframe{
        width: 100%;
        height: 450px;
      }
    }
  }

  &_company{
    margin-bottom: 60px;
    @include m.mq('middle') {
      margin-bottom: 30px;
    }
    &_table{
      margin-bottom: 100px;
      margin-top: -20px;
      @include m.mq('middle') {
        margin-top: 0px;
        margin-bottom: 30px;
      }
      tr{
        th{
          width: 100px;
          text-align: left;
          color: #868686;
          padding: 10px 0;
          vertical-align: top;
          @include m.mq('middle') {
            width: 90px;
            padding: 5px 0;
            vertical-align: top;
          }
        }
        td{
          text-align: left;
          font-weight: bold;
          padding: 10px 0;
          @include m.mq('middle') {
            padding: 5px 0;
          }
        }
      }
    }
    &_logo{
      display: flex;
      flex-wrap: wrap;
      @include m.mq('middle') {
        display: block;
        text-align: center;
      }
      &_item{
        margin-bottom: 25px;
        @include m.mq('middle') {
          margin-bottom: 15px;
        }
        &:nth-child(1){
          margin-right: 140px;
          @include m.mq('middle') {
            margin-right: 0;
          }
        }
        &:nth-child(2){}
        &:nth-child(3){
          margin-right: 60px;
          @include m.mq('middle') {
            margin-right: 0;
          }
        }
        &:nth-child(4){
          margin-right: 260px;
          @include m.mq('middle') {
            margin-right: 0;
          }
        }
      }
    }
  }

  &_law{
    margin-bottom: 0;
    @include m.mq('middle') {
    }
    &_heading{
      font-size: 2rem;
      font-weight: bold;
      padding: 20px 0;
      border-top: 1px solid #333333;
      border-bottom: 1px solid #333333;
      margin-bottom: 60px;
      @include m.mq('middle') {
        font-size: 1.8rem;
        margin-bottom: 45px;
      }
    }
    &_table{
      tr{
        th{
          background: #ECECEC;
          border: 1px solid #DFDFDF;
          text-align: left;
          padding: 10px 15px;
          width: 200px;
          @include m.mq('middle') {
            width: 130px;
          }
        }
        td{
          border: 1px solid #DFDFDF;
          padding: 10px 15px;
          a{
            text-decoration: underline;
          }
        }
      }
    }
  }

  &_policy{
    &_inner{
      padding-top: 120px;
      @include m.mq('middle') {
        padding-top: 60px;
      }
    }
    &_item{
      margin-bottom: 30px;
      @include m.mq('middle') {
        margin-bottom: 20px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &_heading{
        color: #868686;
        margin-bottom: 10px;
      }
      &_txt{
        margin-bottom: 30px;
        font-weight: bold;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          //text-decoration: underline;
        }
      }
    }
  }


}
