@use "./setting/setting" as m;
@use "variable" as var;

.contact{

  &_container{
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 100px;
    @include m.mq('middle') {
      padding: 0 0 45px;
    }
  }
  .policy-check{
    text-align: center;
    margin-bottom: 40px;
    @include m.mq('middle') {
      margin-bottom: 20px;
      font-size: 1.2rem;
    }
    a{
      color: #333;
      text-decoration: underline;
    }
  }
  .btn-submit{
    width: 450px;
    background: #333;
    color: #fff;
    display: block;
    margin: 0 auto;
    font-size: 2rem;
    border-radius: 45px;
    padding: 25px;
    @include m.mq('middle') {
      width: 200px;
      font-size: 1.6rem;
      padding: 20px;
      border-radius: 30px;
    }
  }
  &_table{
    width: 100%;
    margin-bottom: 40px;
    @include m.mq('middle') {
      display: block;
      margin-bottom: 20px;
    }
    p{
      margin: 0;
    }
    input[type="text"],input[type="email"],input[type="tel"],textarea{
      margin: 0;
      padding: 20px;
      border-radius: 10px;
      box-shadow: none;
      //display: inherit;
      @include m.mq('middle') {
        padding: 15px;
      }
      &::placeholder{
        color: #C4C4C4;
      }
    }
    input[type="text"]{
      display: inherit;
    }
    .w-200{
      display: inline-block !important;
      width: 200px;
      margin: 0 15px 15px 0;
    }
    .w-250{
      display: inline-block !important;
      width: 250px;
    }
    .memo{
      font-size: 1.2rem;
      margin-left: 15px;
      @include m.mq('middle') {
        display: block;
        line-height: 1;
        font-size: 1.1rem;
        margin: 15px 0 0 0;
      }
    }
    .p-street-address{
      margin-top: 15px !important;
      @include m.mq('middle') {
        margin-top: 0 !important;
      }
    }
    .hissu{
      display: inline-block;
      color: #fff;
      background: #FF0000;
      font-weight: 500;
      font-size: 1.2rem;
      padding: 5px 10px;
      border-radius: 15px;
      line-height: 1;
      margin-left: 10px;
      position: relative;
      bottom: 1px;
    }
    .note{
      display: block;
      font-size: 1.2rem;
      font-weight: 500;
    }
    tr{
      border-bottom: 1px solid #C4C4C4;
      @include m.mq('middle') {
        display: block;
      }
      &:first-child{
        border-top: 1px solid #C4C4C4;
      }
      th{
        position: relative;
        border-bottom: none;
        text-align: left;
        padding: 45px 0 0;
        width: 27.5%;
        vertical-align: top;
        @include m.mq('middle') {
          display: block;
          width: 100%;
          padding: 15px 0 10px;
        }
      }
      td{
        border-bottom: none;
        padding: 30px 0;
        @include m.mq('middle') {
          width: 100%;
          padding: 5px 0 15px;
          display: block;
        }
        a{
          font-weight: bold;
        }
      }
    }
  }
  .wpcf7-checkbox{
    display: flex;
    flex-direction: column;
  }
  .checkbox_th{
    padding: 30px 0;
    @include m.mq('middle'){
      padding: 15px 0 10px;
    }
  }
}
