@use "./setting/setting" as m;
@use "variable" as var;

.single{

  &-container{
    padding-top: 10px;
    width: 900px;
    margin: 0 auto;
    @include m.mq('middle') {
      width: auto;
      padding: 0;
    }
  }

  &_head{
    margin-bottom: 45px;
    @include m.mq('middle') {
      margin-bottom: 20px;
    }
    &_title{
      font-size: 3rem;
      line-height: 1.46;
      font-weight: 600;
      margin-bottom: 15px;
      @include m.mq('middle') {
        font-size: 2.2rem;
      }
    }
    &_info{
      display: flex;
      align-items: baseline;
      .post-time{
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }

  &_content{
    margin-bottom: 100px;
    @include m.mq('middle') {
      margin-bottom: 60px;
    }
    p{
      font-size: 1.4rem;
      margin-bottom: 15px;
      font-weight: 500;
      @include m.mq('middle') {
        margin-bottom: 10px;
      }
    }
    h1{
      font-size: 2.4rem;
      font-weight: bold;
      padding: 2px 0 2px 25px;
      border-left: 18px solid #333333;
      margin-bottom: 40px;
      @include m.mq('middle') {
        font-size: 2rem;
        padding: 2px 0 2px 15px;
        border-left: 10px solid #333333;
        margin-bottom: 20px;
      }
    }
    h2{
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
      @include m.mq('middle') {
        font-size: 1.8rem;
        margin-bottom: 15px;
      }
    }
    a{
      color: #202f55;
      text-decoration: underline;
    }
  }

  &_navi{
    display: flex;
    justify-content: space-between;
    font-family: var.$font-en;
    font-weight: bold;
    color: #868686;
    margin-bottom: 120px;
    @include m.mq('middle') {
      margin-bottom: 60px;
    }
  }

  &_profile{
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
    padding: 50px 0;
    margin-bottom: 150px;
    @include m.mq('middle'){
      padding: 20px 0;
    margin-bottom: 90px;
    }

    &_content{
      display: flex;
      @include m.mq('middle'){
        // flex-direction: row-reverse;
      }
      figure{
        margin-right: 5%;
        width: 20%;
        @include m.mq('middle'){
          width: 20%;
        }
      }
    }
    &_text{
      width: 78%;
      @include m.mq('middle'){
        width: 75%;
      }
      &_en{
        font-size: 2rem;
        font-weight: bold;
        color: #868686;
        line-height: 1.2;
        @include m.mq('middle'){
          font-size: 1.6rem;
          margin-bottom: 5px;
        }
      }
      &_ja{
        font-size: 1.8rem;
        font-weight: bold;
        color: #868686;
        
      }
      &_detail{
        font-size: 1.4rem;
        margin-top: 5px;
        @include m.mq('middle'){
          font-size: 1.2rem;
          margin-top: 0px;
        }
      }
      &_link{
        margin-top: 30px;
        a{
          color: #202f55;
          text-decoration: underline;
          @include m.mq('middle'){
            font-size: 1.2rem;
            margin-top: 0px;
          }
        }
      }
    }
  }
}
